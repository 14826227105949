import { UserInfo } from "@/features/authentication/context/auth/auth.types";
import { useIsMobile } from "@/hooks/use-is-mobile";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { SEGMENT_KEY } from "../constants/key";
import { ParsedTrackData, TrackData } from "../entities/track-data.types";

const analytics =
  SEGMENT_KEY && AnalyticsBrowser.load({ writeKey: SEGMENT_KEY });

const sendProductionTrack = process.env.NODE_ENV === "production";

export const generateTrackData = <T>(
  data: TrackData<T>,
): ParsedTrackData<T> => {
  const { isMobile } = useIsMobile();

  const parsedData = {
    product: "Gestor",
    product_type: "Gestor",
    origin: "myoffice",
    url_page: data.urlPage,
    user_id: data.userId,
    role: data.role,
    category: data.category,
    value: JSON.stringify(data.value) === "{}" ? null : data.value,
    spot_id: null,
    booking_id: null,
  } as ParsedTrackData;

  return parsedData;
};

type SendTrackData = <T>(
  trackData: ParsedTrackData<T> & { eventName: string },
) => void;

export const sendTrackData: SendTrackData = async ({
  eventName,
  ...trackData
}) => {
  if (analytics && sendProductionTrack) {
    analytics.track(eventName, trackData);
    return;
  }

  console.log(`trackEventManager > segment new: ${eventName}`);
  console.table(trackData);
};

export const sendUserIdentifyData = async (userInfo: UserInfo) => {
  const trackData = {
    email: userInfo?.profile?.email || null,
    is_plg: userInfo?.user?.is_plg || null,
    league_id: userInfo?.league?.id || null,
    league_name: userInfo?.league?.name || null,
    plan_id: userInfo?.plan?.id || null,
    plan_name: userInfo?.plan?.name || null,
    permissions: userInfo?.profile?.permissions || null,
  };

  if (analytics && sendProductionTrack) {
    analytics.identify(`${userInfo.user?.id}`, trackData);
  } else {
    console.log(`trackEventManager > user identify: ${userInfo.user?.id}`);
    console.table(trackData);
  }
};
