import {
  createContext,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useApi } from "@/hooks/use-api";
import { cookieManager } from "@/utils/cookie-manager";
import { AuthContextProps, AuthProviderProps } from "./auth.types";
import { hotjar } from "@/lib/hotjar";
import { clarity } from "@/lib/clarity";
import { useFeatureFlags } from "@/hooks/use-feature-flag";
import { userGuiding } from "@/lib/user-guiding";
import { hubspot } from "@/lib/hubspot";
import { generateTokenHubspotApi } from "../../adapters/generate-token-hubspot";
import { wobaBackendApi } from "@/services/woba-api";

import { clevertap } from "@/lib/clevertap";

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthProvider: FunctionComponent<AuthProviderProps> = ({
  children,
}) => {
  const { isDemo } = useFeatureFlags();

  const {
    data: { token, refreshToken },
  } = useApi({
    path: "auth/refresh-token",
    method: "post",
    body: {
      refreshToken: cookieManager.get<string>({
        cookieName: "u_refresh_token",
      }),
    },
    service: wobaBackendApi,
  });

  const { data: rawUserInfo } = useApi({
    path: "/v1/users/me?fl=profile,plan,spaces,league",
    method: "get",
  });

  const userInfo = useMemo(() => {
    if (!rawUserInfo || !token) return rawUserInfo;

    try {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));

      return {
        ...rawUserInfo,
        profile: {
          ...rawUserInfo.profile,
          permissions: decodedToken.permissions || [],
        },
        user: {
          ...rawUserInfo.user,
          group_id:
            decodedToken.accessGroups.length > 0
              ? decodedToken.accessGroups[0]
              : 0,
          accessGroups: decodedToken.accessGroups,
        },
      };
    } catch (error) {
      console.error("Erro ao decodificar o token:", error);
      return rawUserInfo;
    }
  }, [rawUserInfo, token]);

  const updateUserToken = useCallback(() => {
    if (isDemo) return;

    const actualDate = new Date();
    const expirationDate = new Date(
      new Date(actualDate).setMonth(actualDate.getMonth() + 1),
    );
    cookieManager.set({
      cookieName: "u_token",
      value: token,
      globally: true,
      expirationDate,
    });
    cookieManager.set({
      cookieName: "u_refresh_token",
      value: refreshToken,
      globally: true,
      expirationDate,
    });
  }, [isDemo, token, refreshToken]);

  const signOut = useCallback(() => {
    if (isDemo) return;

    cookieManager.remove({ cookieName: "u_token", globally: true });
    cookieManager.remove({ cookieName: "u_id", globally: true });
    cookieManager.remove({ cookieName: "u_hs", globally: true });
    cookieManager.remove({ cookieName: "u_p", globally: true });
    cookieManager.remove({ cookieName: "u_refresh_token", globally: true });

    wobaBackendApi.post("auth/logout");
    window.location.href = import.meta.env.VITE_PORTAL_URL;
  }, [isDemo]);

  const hotjarIdentify = useCallback(() => {
    const {
      user: { id, first_name, last_name, company_name },
      profile: { phone, email },
      league: { name: leagueName },
    } = userInfo;

    hotjar.identify(`${id}`, {
      companyName: company_name || leagueName,
      userEmail: email,
      userName: `${first_name} ${last_name}`,
      userPhone: phone,
    });
  }, [userInfo.user.id]);

  clarity.initialize();

  const claritydentify = useCallback(() => {
    if (userInfo?.user?.id) {
      const {
        user: { id },
        profile: { email },
      } = userInfo;
      clarity.identifyClarity({
        userId: `${id}`,
        email: email,
      });
    }
  }, [userInfo?.user?.id]);

  const hubspotIdentify = useCallback(async () => {
    const {
      user: { id, first_name, last_name, company_name },
      profile: { email },
    } = userInfo;

    const { token } = await generateTokenHubspotApi({
      company_name,
      email,
      first_name,
      last_name,
      id,
    });

    hubspot.identify(email, token);
  }, [userInfo.user.id]);

  const userGuidingdentify = useCallback(() => {
    const {
      user: { id },
    } = userInfo;

    userGuiding.identifyUserGuiding(id);
  }, [userInfo.user.id]);

  const cleverTapIdentify = useCallback(() => {
    if (userInfo.user.id) {
      clevertap.identity({
        email: userInfo.profile.email,
        name: `${userInfo.user.first_name} ${userInfo.user.last_name}`,
        phone: userInfo.profile.phone,
        id: userInfo.user.id,
      });
    }
  }, [userInfo.user.id]);

  const hasPermission = (permission: string): boolean => {
    return (
      permission === "all" ||
      userInfo?.profile?.permissions.includes(permission)
    );
  };

  useEffect(() => {
    updateUserToken();
  }, [updateUserToken]);

  useEffect(() => {
    hotjarIdentify();
  }, [hotjarIdentify]);

  useEffect(() => {
    userGuidingdentify();
  }, [userGuidingdentify]);

  useEffect(() => {
    claritydentify();
  }, [claritydentify]);

  useEffect(() => {
    hubspotIdentify();
  }, [hubspotIdentify]);

  useEffect(() => {
    cleverTapIdentify();
  }, [cleverTapIdentify]);

  return (
    <AuthContext.Provider value={{ userInfo, hasPermission, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
