import { useCheckPermission } from "@/features/authentication/hooks/useCheckPermission";
import { FunctionComponent, ReactNode } from "react";

type ValidatePermissionProps = {
  permissions: string[];
  mode?: "and" | "or";
  fallback?: ReactNode;
  children: ReactNode;
};

const ValidatePermission: FunctionComponent<ValidatePermissionProps> = ({
  permissions,
  mode = "or",
  fallback = null,
  children,
}) => {
  const { check } = useCheckPermission();

  const isAuthorized = check(permissions, mode);

  return isAuthorized ? <>{children}</> : <>{fallback}</>;
};

export default ValidatePermission;
