import { useWobaBackendApi } from "@/hooks/useWobaBackendApi";
import {
  GetSubscriberProps,
  SubscriberDetailsResponse,
} from "./get-subscribers.types";
import { wobaBackendApi } from "@/services/woba-api";

export const getSubscribersApi = ({
  league,
  page,
  search = "",
  groupId,
  limit,
}: GetSubscriberProps) => {
  const searchParams = new URLSearchParams();

  if (search) {
    searchParams.append("email", `${search}`);
  }

  if (groupId) {
    searchParams.append("group_id", `${groupId}`);
  }

  searchParams.append("page", `${page || 1}`);
  searchParams.append("take", `${limit || 10}`);

  const { data }: SubscriberDetailsResponse = useWobaBackendApi({
    path: `/company-subscribers/${league}/users-subscribers?${searchParams.toString()}`,
    method: "get",
  });

  return {
    data: data.subscribers,
    total: data.total,
    count: data.total,
  };
};

export const getSubscribersApiV2 = async ({
  league,
  page,
  search = "",
  groupId,
  limit,
}: GetSubscriberProps) => {
  const searchParams = new URLSearchParams();

  if (search) {
    searchParams.append("email", `${search}`);
  }

  if (groupId) {
    searchParams.append("group_id", `${groupId}`);
  }

  searchParams.append("page", `${page || 1}`);
  searchParams.append("take", `${limit || 10}`);

  const { data } = await wobaBackendApi.get<SubscriberDetailsResponse["data"]>(
    `/company-subscribers/${league}/users-subscribers?${searchParams.toString()}`,
  );

  return {
    data: data.subscribers,
    total: data.total,
    count: data.total,
  };
};
