import { lazy } from "react";
import "@/config/clevertap";
import { BrowserRouter, Navigate, Route } from "react-router-dom";
import { App } from "./App";
import { DEFAULT_ROUTES, DEMO_ROUTES } from "./constants/routes";

import { ImportProvider } from "./features/members/contexts/import-context";
import { SentryRoutes } from "./lib/sentry";
import { RouteAuth } from "./components/auth/route";

const DashboardPage = lazy(() => import("@/pages/dashboard"));
const ContractsPage = lazy(() => import("@/pages/contracts"));
const PaymentsContractPage = lazy(() => import("@/pages/contracts/payments"));
const HomeOfficeReportPage = lazy(() => import("@/pages/home-office-report"));
const MembersPage = lazy(() => import("@/pages/members"));
const GroupDetailsPage = lazy(() => import("@/pages/members/group-details"));
const ImportMembersPage = lazy(() => import("@/pages/members/import-members"));
const ReviewsPage = lazy(() => import("@/pages/reviews"));
const InsightsPage = lazy(() => import("@/pages/insights"));
const PrivateOfficesPage = lazy(() => import("@/pages/private-offices"));
const PrivateOfficesSchedulingsPage = lazy(
  () => import("@/pages/private-offices/schedulings"),
);
const PackagesPage = lazy(() => import("@/pages/packages"));
const PackageDetailsPage = lazy(() => import("@/pages/packages/details"));
const PaymentsPage = lazy(() => import("@/pages/payments"));

export function Router() {
  return (
    <BrowserRouter>
      <SentryRoutes>
        <Route path={DEFAULT_ROUTES.INDEX} element={<App />}>
          <Route index element={<Navigate to={DEFAULT_ROUTES.DASHBOARD} />} />
          <Route
            path={DEFAULT_ROUTES.DASHBOARD}
            element={
              <RouteAuth>
                <DashboardPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEFAULT_ROUTES.REPORTS}
            element={
              <RouteAuth permissions={["read-private-rooms"]}>
                <HomeOfficeReportPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEFAULT_ROUTES.MEMBERS}
            element={
              <RouteAuth permissions={["read-users"]}>
                <MembersPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEFAULT_ROUTES.GROUP_DETAILS}
            element={
              <RouteAuth permissions={["read-company-groups"]}>
                <GroupDetailsPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEFAULT_ROUTES.MEMBERS_IMPORT}
            element={
              <RouteAuth>
                <ImportProvider>
                  <ImportMembersPage />
                </ImportProvider>
              </RouteAuth>
            }
          />
          <Route
            path={DEFAULT_ROUTES.PAYMENTS}
            element={
              <RouteAuth permissions={["read-invoices"]}>
                <PaymentsPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEFAULT_ROUTES.CONTRACTS}
            element={
              <RouteAuth permissions={["read-contracts"]}>
                <ContractsPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEFAULT_ROUTES.PAYMENTS}
            element={
              <RouteAuth permissions={["read-invoices"]}>
                <PaymentsContractPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEFAULT_ROUTES.REVIEWS}
            element={
              <RouteAuth>
                <ReviewsPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEFAULT_ROUTES.BOOKINGS_REPORT}
            element={<Navigate to={DEFAULT_ROUTES.INSIGHTS} />}
          />
          <Route
            path={DEFAULT_ROUTES.INSIGHTS}
            element={
              <RouteAuth permissions={["read-spaces"]}>
                <InsightsPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEFAULT_ROUTES.PRIVATE_OFFICES}
            element={
              <RouteAuth permissions={["read-private-rooms"]}>
                <PrivateOfficesPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEFAULT_ROUTES.PRIVATE_OFFICES_SCHEDULINGS}
            element={
              <RouteAuth permissions={["read-private-rooms"]}>
                <PrivateOfficesSchedulingsPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEFAULT_ROUTES.PACKAGES}
            element={
              <RouteAuth permissions={["read-packages"]}>
                <PackagesPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEFAULT_ROUTES.PACKAGE}
            element={
              <RouteAuth permissions={["read-packages"]}>
                <PackageDetailsPage />
              </RouteAuth>
            }
          />
        </Route>
        <Route path={DEMO_ROUTES.INDEX} element={<App />}>
          <Route index element={<Navigate to={DEMO_ROUTES.DASHBOARD} />} />
          <Route
            path={DEMO_ROUTES.DASHBOARD}
            element={
              <RouteAuth>
                <DashboardPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEMO_ROUTES.MEMBERS}
            element={
              <RouteAuth permissions={["read-users"]}>
                <MembersPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEMO_ROUTES.PAYMENTS}
            element={
              <RouteAuth permissions={["read-invoice"]}>
                <PaymentsPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEMO_ROUTES.CONTRACTS}
            element={
              <RouteAuth permissions={["read-contracts"]}>
                <ContractsPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEMO_ROUTES.PAYMENTS}
            element={
              <RouteAuth permissions={["read-contracts"]}>
                <PaymentsContractPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEMO_ROUTES.REVIEWS}
            element={
              <RouteAuth>
                <ReviewsPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEMO_ROUTES.BOOKINGS_REPORT}
            element={<Navigate to={DEMO_ROUTES.INSIGHTS} />}
          />
          <Route
            path={DEMO_ROUTES.INSIGHTS}
            element={
              <RouteAuth permissions={["read-bookings"]}>
                <InsightsPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEMO_ROUTES.PRIVATE_OFFICES_SCHEDULINGS}
            element={
              <RouteAuth permissions={["read-private-rooms"]}>
                <PrivateOfficesSchedulingsPage />
              </RouteAuth>
            }
          />
          <Route
            path={DEMO_ROUTES.PACKAGES}
            element={
              <RouteAuth permissions={["read-packages"]}>
                <PackagesPage />
              </RouteAuth>
            }
          />
        </Route>
      </SentryRoutes>
    </BrowserRouter>
  );
}
