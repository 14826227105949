import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { LockKeyhole } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function ModalDenied() {
  const [translate] = useTranslation();
  const navigate = useNavigate();

  return (
    <Dialog open={true}>
      <DialogContent
        closeButton={false}
        className="max-w-[490px] rounded-lg w-full flex flex-col p-0 gap-0"
      >
        <div className="flex flex-col gap-4 p-6">
          <div>
            <LockKeyhole size={48} />
          </div>

          <div className="text-neutral-900 leading-10 font-black text-[32px] text-center">
            {translate("modal-denied-title")}
          </div>

          <div className="text-neutral-600 text-center text-base font-normal">
            {translate("modal-denied-description")}
          </div>
        </div>

        <div className="flex border-t p-6 m-0 border-neutral-200">
          <Button
            variant="green"
            className="flex flex-1 text-sm"
            onClick={() => {
              navigate("/");
            }}
          >
            {translate("modal-denied-button")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
