import { useActionsTrack } from "@/features/analytics";
import ValidatePermission from "../validate-permission";
import { RouteAuthProps } from "./route.interfaces";
import { useEffect } from "react";
import { ModalDenied } from "../modal-denied";
import { useCheckPermission } from "@/features/authentication/hooks/useCheckPermission";

export function RouteAuth(props: RouteAuthProps) {
  const { onActionView } = useActionsTrack();

  const { check } = useCheckPermission();

  useEffect(() => {
    if (props.permissions?.length && !check(props.permissions, "and")) {
      onActionView("PERMISSION_DENIED");
    }
  }, [props.permissions?.length]);

  if (!props.permissions?.length) {
    return props.children;
  }

  return (
    <ValidatePermission
      fallback={<ModalDenied />}
      permissions={props.permissions}
    >
      {props.children}
    </ValidatePermission>
  );
}
