import { useActionsTrack } from "@/features/analytics";
import { INSTRUCTION_DATA } from "@/features/members/constants/instruction-data";
import { OPTIONS_IMPORT } from "@/features/members/constants/options-import";
import { useImport } from "@/features/members/contexts/import-context";
import { cn } from "@/utils/utils";

import { DEFAULT_ROUTES } from "@/constants/routes";
import { subscribersErrosReport } from "@/features/members/contexts/import-context/import-context.types";
import { useDownloadImportMembers } from "@/features/members/hooks/use-download/invalid-import-members/use-download-import-members";
import {
  Table,
  TableBody,
  TableCellBody,
  TableCellHead,
  TableHead,
  TableRow,
  Tabs,
  TabsItem,
  TabsList,
} from "@woba/ds-web";
import { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Empty } from "./shared/empty";
import { ImportDownloadButton } from "./shared/import-download-button";
import { tabOptionsData } from "@/features/members/constants/tab-options-data";

export const ReviewSection = () => {
  const { onActionClick } = useActionsTrack();
  const [translate] = useTranslation(["members"]);
  const [activeReason, setActiveReason] = useState<boolean>(false);
  const [activeDelete, setActiveDelete] = useState<boolean>(false);
  const [msgActiveTab, setMsgActiveTab] = useState<string>("");
  const [tabOption, setTabOption] = useState<
    "new" | "exist" | "invalid" | "delete"
  >("new");
  const { handleExport } = useDownloadImportMembers();
  const [tabEmptyDetails, setTabEmptyDetails] = useState<{
    title: string;
    description: string;
  }>();

  const { importInfo, importData, currentOption, setCurrentOption } =
    useImport();

  useEffect(() => {
    const activeTabMsg: string = translate("members-message-active-tab-new");
    setMsgActiveTab(activeTabMsg);
  }, []);

  const BulletNumber = ({ children }: {} & PropsWithChildren) => {
    return (
      <div className="bg-complementary-500 min-w-6 w-6 h-6 rounded-full flex items-center justify-center text-xs text-neutral-900">
        {children}
      </div>
    );
  };

  const isUpdate = currentOption === "update";

  const arrayMerge = (data: any[]) =>
    data?.map((item) => ({
      firstName: "",
      lastName: "",
      phone: "",
      birthday: "",
      jobRole: "",
      group: "",
      department: "",
      subDepartment: "",
      registrationId: 0,
      postalCode: 0,
      email: item.email,
      reason: "",
    })) || [];

  let REVIEW_TABLE_TABS = [];
  let defaultTabOptions: ("new" | "exist" | "invalid" | "delete")[] = [];

  const totalUpdate =
    (importData?.subscribersToDelete?.length || 0) +
    (importData?.usersToDelete?.length || 0);

  // Carrega a aba de deletar
  const itemIsUpdate = isUpdate
    ? {
        id: "delete",
        title: translate("members-import-step-3-table-tab-4"),
        quanty: totalUpdate,
        color: "bg-danger-200",
        message: translate("members-message-active-tab-delete"),
        data: [
          ...arrayMerge(importData?.subscribersToDelete || []),
          ...arrayMerge(importData?.usersToDelete || []),
        ],
      }
    : {};

  // Carrega a aba de novos
  REVIEW_TABLE_TABS.push({
    id: "new",
    title: translate("members-import-step-3-table-tab-1"),
    quanty: importData?.subscribersToInsert?.length,
    color: "bg-green-200",
    message: translate("members-message-active-tab-new"),
    data: importData?.subscribersToInsert?.map((item) => {
      return {
        reason: "",
        ...item,
      };
    }),
  });
  importData?.subscribersToInsert?.length && defaultTabOptions.push("new");

  // Carrega a aba de existentes
  const totalExist =
    (importData?.usersToUpdate?.length ?? 0) +
    (importData?.subscribersToUpdate?.length ?? 0);

  REVIEW_TABLE_TABS.push({
    id: "exist",
    title: translate("members-import-step-3-table-tab-2"),
    quanty: totalExist,
    color: "bg-info-200",
    message: translate("members-message-active-tab-exist"),
    data: [
      ...(importData?.usersToUpdate?.map((item) => ({
        reason: "",
        ...item,
      })) || []),
      ...(importData?.subscribersToUpdate?.map((item) => ({
        reason: "",
        ...item,
      })) || []),
    ],
  });
  totalExist && defaultTabOptions.push("exist");

  // Carrega a aba de inválidos
  REVIEW_TABLE_TABS.push({
    id: "invalid",
    title: translate("members-import-step-3-table-tab-3"),
    quanty: importData?.subscribersErrors?.length,
    color: "bg-warning-200",
    message: translate("members-message-active-tab-invalid"),
    data: importData?.subscribersErrors?.map((item) => {
      return {
        reason: `members-error-${item.error.errorKeyCode}-${item.error.errorCode}`,
        ...item.subscriber,
      };
    }),
  });
  (importData?.subscribersErrors ?? []).length > 0 &&
    defaultTabOptions.push("invalid");

  // Adiciona a aba de deletar
  REVIEW_TABLE_TABS.push(itemIsUpdate);
  totalUpdate && defaultTabOptions.push("delete");

  const getTabDetails = (option: string) => {
    return (
      tabOptionsData[option as keyof typeof tabOptionsData] || {
        title: "Opção não encontrada",
        description: "",
      }
    );
  };

  const defaultTab = defaultTabOptions[0] as
    | "new"
    | "exist"
    | "invalid"
    | "delete"
    | undefined;

  useEffect(() => {
    if (defaultTab && defaultTab === "invalid") {
      setActiveReason(true);
      setMsgActiveTab(translate("members-message-active-tab-invalid") || "");
    }
    defaultTab && setTabOption(defaultTab);
  }, [defaultTab]);

  useEffect(() => {
    if (tabOption) {
      setTabEmptyDetails(getTabDetails(tabOption));
    }
  }, [tabOption]);

  return (
    <div className="pt-6">
      <div className="flex gap-4 h-[calc(100vh-280px)]">
        <div className="max-w-[30%]">
          <div className="flex gap-2">
            <BulletNumber>1</BulletNumber>
            <div>
              <h4 className="font-normal text-sm text-neutral-900 leading-4">
                {translate("members-import-step-3-bullet-1-title")}
              </h4>
              <p className="font-normal text-sm text-neutral-700 leading-4">
                {translate("members-import-step-3-bullet-1-description")}
              </p>
            </div>
          </div>
          <div className="my-4 flex flex-col gap-4">
            {Object.entries(OPTIONS_IMPORT).map((item) => (
              <div
                className={cn(
                  "border-[1px] border-neutral-200 p-4 rounded-lg cursor-pointer",
                  currentOption === item[0]
                    ? "bg-neutral-200 border-neutral-900"
                    : "",
                )}
                key={item[0]}
                onClick={() => {
                  setCurrentOption(item[0] as "new" | "update");
                  onActionClick("MEMBERS_IMPORT_TYPE_OPTION", "MEMBERS", {
                    url_page: DEFAULT_ROUTES.MEMBERS_IMPORT,
                    option: item[0],
                  });
                }}
              >
                <div className="flex gap-2 items-start">
                  <div
                    className={cn(
                      "w-4 h-4 min-w-4 min-h-4 border rounded-full relative flex items-center justify-center",
                      currentOption === item[0] &&
                        "border-neutral-900 bg-neutral-500 before:content-[''] before:bg-white before:absolute before:rounded-full before:w-2 before:h-2",
                    )}
                  ></div>
                  <div>
                    <h4 className="text-neutral-900 leading-4 text-sm mb-2">
                      {translate(item[1].title)}
                    </h4>
                    <p className="flex flex-col font-normal text-sm mb-2 text-neutral-700">
                      {translate(item[1].description)}
                      <p>{translate(`${item[1].description}2`)}</p>
                      <span className="mt-4">{translate(item[1].alert)}</span>
                    </p>
                    <div className="flex w-full gap-2">
                      <div className="flex flex-col border-[1px] border-neutral-200 p-4 rounded-[4px] flex-1 bg-white">
                        <span className="uppercase text-neutral-700 text-[10px] font-medium">
                          {translate("members-import-step-3-card-before")}
                        </span>
                        <span className="text-neutral-900 text-base font-medium">
                          {importInfo?.total}{" "}
                          {translate("members-import-step-3-card-members")}
                        </span>
                      </div>
                      <div className="flex flex-col border-[1px] border-neutral-200 p-4 rounded-[4px] flex-1 bg-white">
                        <span className="uppercase text-neutral-700 text-[10px] font-medium">
                          {translate("members-import-step-3-card-after")}
                        </span>
                        <span className="text-neutral-900 text-base font-medium">
                          {item[0] === "update"
                            ? importInfo?.afterUpdate
                            : importInfo?.afterNew}{" "}
                          {translate("members-import-step-3-card-members")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="max-w-[70%] w-full">
          <div className="flex gap-2">
            <BulletNumber>2</BulletNumber>
            <div>
              {" "}
              <h4 className="font-normal text-sm text-neutral-900 leading-4">
                {translate("members-import-step-3-bullet-2-title")}
              </h4>
              <p className="font-normal text-sm text-neutral-700 leading-4">
                {translate("members-import-step-3-bullet-2-description")}
              </p>
            </div>
          </div>
          <div className="mt-4 w-full h-full max-h-[calc(100vh-350px)]">
            {currentOption === "" ? (
              <Empty />
            ) : (
              <>
                <Tabs defaultValue={defaultTab}>
                  <TabsList className="border-b border-neutral-100">
                    {REVIEW_TABLE_TABS.map((item, index) => (
                      <TabsItem
                        key={index}
                        value={item?.id as string}
                        onClick={() => {
                          setTabOption(item?.id as "new" | "exist" | "invalid");
                          setActiveReason(item?.id === "invalid");
                          setActiveDelete(item?.id === "delete");
                          setMsgActiveTab(item?.message as string);
                          onActionClick("MEMBERS_IMPORT_TABS", "MEMBERS", {
                            url_page: DEFAULT_ROUTES.MEMBERS_IMPORT,
                            option: currentOption,
                            tabs: item?.title,
                          });
                        }}
                        className={cn(
                          "max-w-28 [&[data-state=active]]:bg-neutral-200 after:hidden rounded-b-none rounded-t-lg ",
                          {},
                        )}
                      >
                        <span className="flex gap-1 items-center text-nowrap text-[12px]">
                          {item?.title}{" "}
                          <div
                            className={cn(
                              "p-1 rounded text-xs font-normal leading-4",
                              `${item?.color}`,
                            )}
                          >
                            {item?.quanty}
                          </div>
                        </span>
                      </TabsItem>
                    ))}
                  </TabsList>
                  {defaultTabOptions.includes(tabOption) && (
                    <div className="border border-tertiary-200 rounded-tr-md rounded-tl-md px-4 py-3 text-sm text-neutral-700 flex justify-between items-start">
                      {msgActiveTab}
                      {activeReason &&
                        (() => {
                          const data = REVIEW_TABLE_TABS.filter(
                            (item) => item.id === "invalid",
                          )[0]?.data as subscribersErrosReport[];

                          return (
                            <ImportDownloadButton
                              data={data}
                              type="invalid"
                              label="members-import-invalid-button-download"
                            />
                          );
                        })()}
                      {activeDelete &&
                        (() => {
                          const data = REVIEW_TABLE_TABS.filter(
                            (item) => item.id === "delete",
                          )[0]?.data as subscribersErrosReport[];

                          return (
                            <ImportDownloadButton
                              data={data}
                              type="delete"
                              label="members-import-remove-users-button-download"
                            />
                          );
                        })()}
                    </div>
                  )}
                </Tabs>

                <div
                  className="max-h-[calc(100vh-340px)] min-h-[calc(100vh-340px)] flex flex-col justify-center"
                  id="table-review"
                >
                  {defaultTabOptions.includes(tabOption) ? (
                    <Table>
                      <TableHead>
                        <TableRow data-type="head">
                          {INSTRUCTION_DATA.body.map((item, index) => {
                            if (activeReason && index === 0) {
                              return (
                                <TableCellHead
                                  key={index}
                                  className="border-[1px] border-tertiary-200 min-w-[250px] text-sm"
                                >
                                  <div className="flex items-center text-nowrap gap-2">
                                    {translate(item.column)}
                                  </div>
                                </TableCellHead>
                              );
                            } else if (index > 0) {
                              return (
                                <TableCellHead
                                  key={index}
                                  className="border-[1px] border-tertiary-200 text-sm"
                                >
                                  <div className="flex items-center text-nowrap gap-2">
                                    {translate(item.column)}{" "}
                                    <div
                                      className={cn(
                                        " px-4 py-1 rounded",
                                        item.require
                                          ? "bg-warning-200"
                                          : "bg-tertiary-200",
                                      )}
                                    >
                                      {item.require
                                        ? translate(
                                            "members-import-instruction-require",
                                          )
                                        : translate(
                                            "members-import-instruction-optional",
                                          )}
                                    </div>
                                  </div>
                                </TableCellHead>
                              );
                            }
                            return null;
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {REVIEW_TABLE_TABS?.filter(
                          (item) => item?.id === tabOption,
                        )[0]?.data?.map((item) => (
                          <TableRow>
                            {activeReason && (
                              <TableCellBody className="border-[1px] border-tertiary-200 text-sm">
                                {item?.reason && item?.reason !== undefined
                                  ? translate(item?.reason)
                                  : "-"}
                              </TableCellBody>
                            )}
                            <TableCellBody className="border-[1px] border-tertiary-200 text-sm">
                              {item?.firstName}
                            </TableCellBody>
                            <TableCellBody className="border-[1px] border-tertiary-200 text-sm">
                              {item?.lastName}
                            </TableCellBody>
                            <TableCellBody className="border-[1px] border-tertiary-200 text-sm">
                              {item?.email}
                            </TableCellBody>
                            <TableCellBody className="border-[1px] border-tertiary-200 text-sm">
                              {item?.phone}
                            </TableCellBody>
                            <TableCellBody className="border-[1px] border-tertiary-200 text-sm">
                              {item?.birthday}
                            </TableCellBody>
                            <TableCellBody className="border-[1px] border-tertiary-200 text-sm">
                              {item?.jobRole}
                            </TableCellBody>
                            <TableCellBody className="border-[1px] border-tertiary-200 text-sm">
                              {item?.group}
                            </TableCellBody>
                            <TableCellBody className="border-[1px] border-tertiary-200 text-sm">
                              {item?.department}
                            </TableCellBody>
                            <TableCellBody className="border-[1px] border-tertiary-200 text-sm">
                              {item?.subDepartment}
                            </TableCellBody>
                            <TableCellBody className="border-[1px] ">
                              <div className="flex gap-2 items-center px-4 py-3 text-sm">
                                {item?.registrationId}
                              </div>
                            </TableCellBody>
                            <TableCellBody className="border-[1px] border-tertiary-200 text-sm">
                              {item?.postalCode}
                            </TableCellBody>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <div className="font-xxl font-medium flex flex-col w-full h-full items-center justify-center">
                      <h2 className="text-neutral-900 text-4xl font-bold leading-10 tracking-tighter mb-4">
                        {translate(tabEmptyDetails?.title ?? "")}
                      </h2>
                      <p className="text-neutral-700 text-center font-body text-sm font-normal leading-4 max-w-sm">
                        {translate(tabEmptyDetails?.description ?? "")}
                      </p>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
