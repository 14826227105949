import { FunctionComponent } from "react";

import * as S from "./card-team.styles";
import { CardTeamProps } from "./card-team.types";
import { Tooltip } from "@woba/ds-web";
import { useTranslation } from "react-i18next";

export const CardTeam: FunctionComponent<CardTeamProps> = ({
  image,
  description,
  name,
}) => {
  const [translate] = useTranslation();

  return (
    <Tooltip content={translate("tooltip-only-group")}>
      <S.ContainerCardTeam>
        <S.ContentCardTeam>
          {image ? (
            <S.Image>
              <img src={image} alt={name} />
            </S.Image>
          ) : null}

          <span>{name}</span>
        </S.ContentCardTeam>

        {description ? (
          <div className="text-neutral-600 text-[12px] mt-[-4px]">
            {description}
          </div>
        ) : null}
      </S.ContainerCardTeam>
    </Tooltip>
  );
};
