import { ReactComponent as ContractsIcon } from "@/assets/icons/sidebar/contracts.svg";
import { ReactComponent as DashboardIcon } from "@/assets/icons/sidebar/dashboard.svg";
import { ReactComponent as InsightsIcon } from "@/assets/icons/sidebar/insights.svg";
import { ReactComponent as MembersIcon } from "@/assets/icons/sidebar/members.svg";
import { ReactComponent as ReportsIcon } from "@/assets/icons/sidebar/reports.svg";
import { ReactComponent as PackagesIcon } from "@/assets/icons/sidebar/packages.svg";
import { ReactComponent as PaymentsIcon } from "@/assets/icons/sidebar/payments.svg";
import { ReactComponent as DoorIcon } from "@/assets/icons/sidebar/door.svg";
import { ROUTE, ROUTES } from "./routes";

export const navItems = [
  {
    id: "dashboard",
    name: "app-menu-dashboard",
    link: ROUTES.DASHBOARD as ROUTE,
    icon: <DashboardIcon />,
  },
  {
    id: "insights",
    name: "app-menu-utilization",
    link: ROUTES.INSIGHTS as ROUTE,
    icon: <InsightsIcon />,
  },
  {
    id: "private-offices",
    name: "app-menu-private-offices",
    link: ROUTES.PRIVATE_OFFICES as ROUTE,
    icon: <DoorIcon />,
    tracker: "PAGE_PRIVATE_OFFICES_VIEW",
    permissions: ["read-private-rooms"],
  },
  {
    id: "members",
    name: "app-menu-members",
    link: ROUTES.MEMBERS as ROUTE,
    icon: <MembersIcon />,
  },
  {
    id: "payments",
    name: "app-menu-payments",
    link: ROUTES.PAYMENTS as ROUTE,
    tracker: "PAGE_PAYMENTS_VIEW",
    icon: <PaymentsIcon />,
    permissions: ["read-invoices"],
  },
  {
    id: "contracts",
    name: "app-menu-contracts",
    link: ROUTES.CONTRACTS as ROUTE,
    icon: <ContractsIcon />,
    permissions: ["read-contracts"],
  },
  {
    id: "packages",
    name: "app-menu-packages",
    link: ROUTES.PACKAGES as ROUTE,
    icon: <PackagesIcon />,
  },
];

export const itemReports = [
  {
    id: "reports",
    name: "app-menu-ho-reports",
    link: ROUTES.REPORTS as ROUTE,
    icon: <ReportsIcon />,
  },
];
