import wobaLogo from "@/assets/woba.svg";
import { FunctionComponent } from "react";
import { SidebarNavigationProps } from "./sidebar-navigation.types";

import {
  CardTeam,
  Sidebar,
  SidebarNav,
} from "@/components/ds-components/navigation/sidebar";

import { itemReports, navItems } from "@/constants/items-menu";
import { useChangeRouteTrack } from "@/features/analytics";
import { useAuth } from "@/features/authentication";
import { useFeatureFlags } from "@/hooks/use-feature-flag";

export const SidebarNavigation: FunctionComponent<
  SidebarNavigationProps
> = () => {
  const { userInfo, hasPermission } = useAuth();
  const { onChangeRoute } = useChangeRouteTrack();

  const { isReportEnabled } = useFeatureFlags(userInfo);

  const hasGroupsPermissioms = hasPermission("read-company-groups");

  return (
    <Sidebar>
      {hasGroupsPermissioms ? (
        <CardTeam name={userInfo.league.name} image={wobaLogo} />
      ) : null}

      {!hasGroupsPermissioms ? (
        <CardTeam
          name={userInfo.league.name}
          description={userInfo.user.group?.name}
        />
      ) : null}

      <SidebarNav
        items={isReportEnabled ? [...navItems, ...itemReports] : navItems}
        onChange={onChangeRoute}
      />
    </Sidebar>
  );
};
