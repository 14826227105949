import { useCallback } from "react";
import { useAuth } from "../context/auth";

export function useCheckPermission() {
  const { hasPermission } = useAuth();

  const check = useCallback((permissions: string[], mode?: "or" | "and") => {
    const isAuthorized =
      mode === "and"
        ? permissions.every((permission) => hasPermission(permission))
        : permissions.some((permission) => hasPermission(permission));

    return isAuthorized;
  }, []);

  return {
    check,
  };
}
